import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { Subnav as SubnavComponent } from "@hurleymc/components"

import { Link } from "./link"

const Subnav = ({ entry }) => {
  const { craft } = useStaticQuery(
    graphql`
      {
        craft {
          entries(level: 1, section: ["gmePage"], site: "hurleyGme") {
            ...Subnav
          }
        }
      }
    `
  )
  const { entries } = craft
  if (entries && entries.length > 0) {
    return (
      <SubnavComponent
        current={entry}
        entries={entries}
        components={{ Link }}
      />
    )
  }
  return null
}

export default Subnav
